import styled from "styled-components"

export const Logo = styled.div`
  border-radius: 100%;
  background-color: #fff;
  color: #18191b;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-size: 1.3rem;
  overflow: hidden;
  @media only screen and (min-width: 1024px) {
    width: 40px;
    height: 40px;
    font-size: 1.8rem;
  }
`
