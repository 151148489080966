import { useMemo, useRef, useState } from "react"
// validation
import { Formik, Form } from "formik"

// components & elem
import Container from "@/components/Container"
import FormObserver from "@/components/FormFields/FormObserver"
// import { ButtonBase } from "@/components/NotarizationButton"
import SurveyFormRadio from "@/survey/variants/SurveyFormRadio"
import SurveyFormRating from "@/survey/variants/SurveyFormRating"
import SurveyFormSelect from "@/survey/variants/SurveyFormRadio"
// import Typography from "@/components/Typography"
import QuizResults from "@/quiz/QuizResults/QuizResults.js"

import { QuizFlagIcon } from "@/icons"
// import { graphql, useStaticQuery } from "gatsby"
// import graphqlClient from "@/graphql/client"
// import ReactGA from "react-ga"
// import { initializeApollo } from "../../../lib/apolloClient"
// import { CREATE_SURVEY_DATA } from "@/graphql/mutations"
// import { CREATE_SURVEY } from "../../../lib/queries"

// styles & animation
import styled from "styled-components"
import { AnimatePresence, motion } from "framer-motion"

const SurveyFormRoot = styled.div`
  /* min-height: 700px; */
  min-height: 100vh;

  /* потестить с/без */
  overflow: hidden;
  /*  */

  padding: 90px 0;
  color: #8a8b8d;
  background-color: #18191b;

  @media only screen and (max-width: 1024px) {
    padding: 27px 0;
  }

  @media only screen and (max-height: 800px) {
    padding: 24px 0;
  }
`
const MyContainer = styled(Container)`
  max-width: 1040px;
  width: 100%;
  padding: 0 48px;

  @media only screen and (max-width: 768px) {
    padding: 0 13px;
  }
`

const SurveyFormContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`

// styles Header
const SurveyFormHeader = styled.div`
  align-self: stretch;
  margin-bottom: 130px;
  @media (max-width: 1024px) {
    margin-bottom: 90px;
  }
  @media (max-width: 768px) {
    margin-bottom: 60px;
  }
`
const SurveyFormProgressInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  .steps-info {
    font-size: 30px;
    line-height: 140%;
    letter-spacing: -0.05em;
    color: #727377;
  }
  @media (max-width: 1024px) {
    .steps-info {
      font-size: 18px;
    }
  }
  @media (max-width: 480px) {
    .steps-info {
      font-size: 14px;
    }
    margin-bottom: 6px;
  }
`
const SurveyFormProgressPoint = styled.div`
  svg {
    @media (max-width: 1024px) {
      width: 20px;
      height: auto;
    }
    @media (max-width: 480px) {
      width: 14px;
      height: auto;
    }
  }
`
const SurveyFormProgress = styled.div`
  position: relative;
  background: #515254;
  height: 7px;
  border-radius: 24px;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: ${p => p.value || 0}%;
    background-color: ${p => p.theme.colors.primary};
    border-radius: inherit;
  }

  @media (max-width: 768px) {
    height: 4px;
    border-radius: 13.2px;
  }
`
//
// styles quiz wrap
const SurveyFormWrap = styled.div`
  flex-grow: 1;
  margin: 0 auto;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    /* max-width: 420px; */
  }
`

const SurveyFormTitle = styled.h4`
  text-align: center;
  font-size: 42px;
  font-weight: 600;
  line-height: 109.84%;
  color: #fff;
  margin-bottom: 70px;

  @media (max-width: 1024px) {
    font-size: 30px;
    margin-bottom: 45px;
  }
  @media (max-width: 768px) {
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: normal;
    text-align: left;
    line-height: 130%;
    letter-spacing: -0.01em;
  }
`

const Quiz = ({ dataQuiz, onToggleScreen }) => {
  const initialState = {
    step: 0,
    totalSteps: dataQuiz.length,
    stepData: dataQuiz[0],
    direction: 0,
  }
  const timer = useRef(null)
  const [state, setState] = useState(initialState)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [animating, setAnimating] = useState(false)
  const [finish, setFinish] = useState(null)

  const initialValues = useMemo(
    () =>
      Object.fromEntries(
        Array.from(new Set(dataQuiz.map(el => el.name))).map(el => [el, ""])
      ),
    []
  )
  const delayCall = fn => {
    if (timer) {
      clearTimeout(timer.current)
    }
    timer.current = setTimeout(fn, 350)
  }
  const moveStep = (val, animate, data) => {
    const fn = () => {
      setState(prevState => {
        const nextStep = prevState.step + val

        if (nextStep < 0) {
          return initialState
        }

        if (nextStep == prevState.totalSteps) {
          let resMap = []
          dataQuiz.forEach(el => {
            resMap = [...resMap, { ...el, answer: data[el.name] }]
          })
          setFinish(resMap)
          return { ...prevState, finished: true }
        }

        return {
          ...prevState,
          step: nextStep,
          stepData: dataQuiz[nextStep],
          direction: val,
        }
      })
    }

    animate ? delayCall(fn) : fn()
  }

  const handleFormChange = (values, ctx) => {
    setAnimating(true)
    moveStep(1, true, values)
  }

  const renderFormContent = ({ variant, name, options }) => {
    switch (variant) {
      case "radio":
        return <SurveyFormRadio name={name} options={options} />
      case "rating":
        return <SurveyFormRating name={name} options={options} />
      case "select":
        return <SurveyFormSelect name={name} options={options} />
      default:
        return null
    }
  }

  return (
    <div>
      <SurveyFormRoot>
        {!state.finished ? ( //////////////// !
          <MyContainer css="height: 100%">
            {loading || error ? (
              <div
                css={`
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                `}
              >
                {error ? error.message : "Loading..."}
              </div>
            ) : (
              <Formik initialValues={initialValues} onSubmit={() => null}>
                {({ values }) => {
                  return (
                    <SurveyFormContent>
                      <SurveyFormHeader>
                        <SurveyFormProgressInfo>
                          <div className="steps-info">
                            {state.totalSteps > 1 &&
                              `${state.step + 1} / ${state.totalSteps}`}
                          </div>

                          <SurveyFormProgressPoint>
                            <QuizFlagIcon />
                          </SurveyFormProgressPoint>
                        </SurveyFormProgressInfo>

                        <SurveyFormProgress
                          value={Math.round(
                            (state.step / state.totalSteps) * 100
                          )}
                        />
                      </SurveyFormHeader>

                      {/* FORM */}
                      <SurveyFormWrap>
                        <SurveyFormTitle>
                          {state.stepData.title}
                        </SurveyFormTitle>
                        <div
                          css={`
                            position: relative;
                            max-width: 600px;
                            width: 100%;
                          `}
                        >
                          <Form>
                            <FormObserver onChange={handleFormChange} />
                            <AnimatePresence
                              custom={state.direction}
                              exitBeforeEnter
                              onExitComplete={() => setAnimating(false)}
                            >
                              <motion.div
                                key={state.stepData.name}
                                custom={state.direction}
                                initial="enter"
                                animate="center"
                                exit="exit"
                                variants={{
                                  enter: d => ({
                                    opacity: 0,
                                    x: d > 0 ? 200 : -200,
                                  }),
                                  center: { opacity: 1, x: 0 },
                                  exit: d => ({
                                    opacity: 0,
                                    x: d > 0 ? -200 : 200,
                                  }),
                                }}
                                transition={{
                                  ease: "easeInOut",
                                  duration: 0.4,
                                }}
                              >
                                {renderFormContent(state.stepData)}
                              </motion.div>
                            </AnimatePresence>
                          </Form>
                        </div>
                      </SurveyFormWrap>
                    </SurveyFormContent>
                  )
                }}
              </Formik>
            )}
          </MyContainer>
        ) : (
          <QuizResults
            data={finish}
            onToggleScreen={onToggleScreen}
            onTryAgain={() => setState(initialState)}
          />
        )}
      </SurveyFormRoot>
    </div>
  )
}

export default Quiz
