import { forwardRef } from "react";

import { useField } from "formik";
import styled from "styled-components";

const SurveyFormRatingFieldRoot = styled.label`
  width: 60px;
  height: 60px;
  background: ${(p) => (p.active ? p.theme.colors.primary : "#212225")};
  border: 1px solid ${(p) => (p.active ? p.theme.colors.primary : "#212225")};
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => (p.active ? "#000" : "#fff")};

  input {
    display: none;
  }

  @media (max-width: 1024px) {
    width: 48px;
    height: 48px;
  }

  @media (max-width: 360px) {
    width: 32px;
    height: 32px;
  }
`;

const SurveyFormRatingFieldLabel = styled.div`
  font-size: 20px;

  @media (max-width: 1024px) {
    font-size: 18px;
  }

  @media (max-width: 360px) {
    font-size: 14px;
  }
`;

const SurveyFormRatingField = forwardRef(
  ({ label, name, value, ...rest }, ref) => {
    const [field, meta] = useField({ name, type: "radio" });

    return (
      <SurveyFormRatingFieldRoot active={meta.value === value}>
        <input type="radio" ref={ref} {...rest} {...field} value={value} />
        <SurveyFormRatingFieldLabel>{label}</SurveyFormRatingFieldLabel>
      </SurveyFormRatingFieldRoot>
    );
  }
);

export default SurveyFormRatingField;
