import { ButtonBase } from "@/components/NotarizationButton"
import styled from "styled-components"
export const OuterContainer = styled.div`
  position: relative;
  user-select: none;
`
export const InnerContainer = styled.div`
  /* MOBILE */
  display: flex;
  min-height: 100vh;

  .left {
    .log-out {
      margin-top: 5.8rem;
      margin-bottom: 3.2rem;
      font-size: 1.8rem;
      line-height: 120%;
      letter-spacing: -0.03em;
      color: #ff5454;
    }
  }
  .left-mobile {
  }
  .left-desktop {
    display: none;
  }
  .right {
    width: 100%;
    padding: 3rem 1.6rem 1rem;
  }

  .list-item {
    padding: 1.6rem 0 1.6rem 0.3rem;
  }
  .primary-text {
    font-size: 1.6rem;
    margin-bottom: 0.3rem;
    line-height: 139%;
    color: #25272b;
  }

  @media only screen and (min-width: 768px) {
    .right {
      padding-right: 3rem;
      padding-left: 3rem;
    }
    .primary-text {
      font-size: 1.75rem;
    }
  }

  /* DESKTOP */
  @media only screen and (min-width: 1024px) {
    max-width: 1600px;
    margin: 0 auto;

    .left {
      position: relative;
      width: 36%;
      padding: 2rem 2rem 0.5rem;
      border-right: 1px solid #ededed;
      .log-out {
        margin-top: 10rem;
      }
    }
    .left-mobile {
      display: none;
    }
    .left-desktop {
      display: block;
    }
    .right {
      width: 74%;
      padding: 4rem 4rem 0.5rem;
    }

    .list-item {
      padding: 2rem 0 2rem 0.3rem;
    }
    .primary-text {
      font-size: 2rem;
      margin-bottom: 0.7rem;
      line-height: 120%;
    }
  }
  @media only screen and (min-width: 1440px) {
    .left {
      padding: 5rem 6rem 1rem;
    }
    .right {
      padding: 6rem 8rem 1rem;
    }
  }
`
export const MenuMobileButton = styled(ButtonBase)`
  position: absolute;
  top: 1.5rem;
  left: 1rem;
  font-size: 1.8rem;
  padding: 1rem;
  svg {
    path {
      fill: #fff;
    }
  }
`