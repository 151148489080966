import React from "react"
import styled from "styled-components"
import useMediaQuery from "@/hooks/useMediaQuery"

const ProgressContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 10px;
  color: #fff;

  .line-md {
    display: block;
    width: 50%;
    z-index: 10;
    height: 3px;
    bottom: 0;
    left: 0;
    position: absolute;
    background-color: #ffc700;
  }
  .line-lg {
    display: none;
  }

  @media only screen and (min-width: 1024px) {
    .top-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h3 {
        font-size: 1.4rem;
        font-weight: 400;
        text-transform: capitalize;
      }
    }
    .percent {
      font-size: 12px;
      line-height: 13px;
      letter-spacing: -0.03em;
    }
    .line-wrap {
      width: 100%;
      background-color: #fff;
      height: 6px;
      border-radius: 5px;
      position: relative;
      overflow: hidden;
    }
    .line-md {
      display: none;
    }
    .line-lg {
      display: block;
      width: 0%;
      z-index: 10;
      height: 6px;
      background-color: #ffc700;
    }
  }
`

const Progress = ({ progressInfo, name: { firstName, lastName } }) => {
  const modifiedProgressInfo = Math.round(progressInfo)
  const isMD = useMediaQuery({ minWidth: 1024 })
  return (
    <ProgressContainer>
      {!isMD ? (
        <div
          className="line-md"
          style={{ width: `${modifiedProgressInfo}%` }}
        ></div>
      ) : (
        <>
          <div className="top-info">
            <h3>
              {firstName} {lastName}
            </h3>
            <span className="percent">{modifiedProgressInfo || 0}%</span>
          </div>
          <div className="line-wrap">
            <div
              className="line-lg"
              style={{ width: `${modifiedProgressInfo}%` }}
            ></div>
          </div>
        </>
      )}
    </ProgressContainer>
  )
}

export default Progress
