import * as React from "react"

// animations
// import { AnimatePresence, motion } from "framer-motion"

// components && elem
import CourseStepper from "../CourseStepper"
import Box from "@mui/material/Box"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import Paper from "@mui/material/Paper"
import Collapse from "@mui/material/Collapse"

// icons
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown"
import { WatchIcon, PadlockIcon } from "@/icons"

// styles
import styled from "styled-components"
const NestedListContainer = styled.div`
  /* font-family: "Graphik" !important; */
  font-weight: 400;
  letter-spacing: -0.03em;

  .list-item {
    padding: 1.6rem 0 1.6rem 0.3rem;
  }
  .primary-text {
    font-size: 1.6rem;
    margin-bottom: 0.3rem;
    line-height: 139%;
    color: #25272b;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    svg {
      position: relative;
      left: 2.5rem;
      display: inline-block;
      margin-left: 0.5rem;
      min-width: 24px;
      min-height: 24px;
      opacity: 1 !important;
    }
  }
  .secondary-text {
    display: block;
    font-size: 1.2rem;
    line-height: 120%;
    color: #9b9da4;

    .stick {
      display: inline-block;
      position: relative;
      top: 2px;
      height: 12px;
      width: 1px;
      background-color: #ced0d7;
      margin: 0 15px;
    }
    svg {
      display: inline-block;
      position: relative;
      top: 2px;
      margin-right: 5px;
      opacity: 1 !important;
    }
  }
  .css-1jumdnh-MuiPaper-root {
    border-radius: 0;
  }

  @media only screen and (min-width: 768px) {
    .primary-text {
      font-size: 1.75rem;
    }
    .secondary-text {
      font-size: 1.3rem;
    }
  }

  @media only screen and (min-width: 1024px) {
    .list-item {
      padding: 2rem 0 2rem 0.3rem;
    }
    .primary-text {
      font-size: 2rem;
      margin-bottom: 0.7rem;
      line-height: 120%;
    }

    .secondary-text {
      font-size: 1.4rem;
    }
  }
`
const CourseStepperContainer = styled.div`
  padding: 0.2rem 0rem 3rem;
  @media only screen and (min-width: 1024px) {
    padding: 1.5rem 0rem 4.5rem;
  }
`

const CourseNestedList = ({
  data,
  idx,
  open,
  lastItem,
  currentStep,
  lastStep,
  onToggleItem,
  handleStepLink,
}) => {
  const disabled = idx > lastItem && 0
  const complete = idx < lastItem && data.steppersData.length
  const current = idx === lastItem && lastStep + 1

  const primaryText = (
    <div className="primary-text">
      <span>{data.labelItem}</span>
      {idx > lastItem ? <PadlockIcon /> : null}
    </div>
  )
  const secondaryText = (
    <span className="secondary-text">
      {disabled}
      {complete}
      {current} / {data.steppersData.length} <span className="stick"></span> {<WatchIcon />}
      {data.totalDuration} min
    </span>
  )

  return (
    <NestedListContainer>
      <Paper elevation={0} sx={{ maxWidth: "100%" }}>
        <Box>
          <ListItemButton
            alignItems="flex-start"
            onClick={onToggleItem}
            disabled={idx > lastItem ? true : false}
            className= 'list-item'
            sx={{
              "&:hover, &:focus": {
                "& svg": { opacity: open === idx ? 1 : 0 },
              },
              borderBottom: open === idx ? "none" : "1px solid #eaeaea",
            }}
          >
            <ListItemText
              primaryTypographyProps={{
                fontFamily: "Graphik",
              }}
              secondary={secondaryText}
              secondaryTypographyProps={{
                fontFamily: "Graphik",
              }}
              sx={{ my: 0 }}
            >
              {primaryText}
            </ListItemText>

            <KeyboardArrowDown
              sx={{
                mr: 1,
                opacity: 0,
                transform: open === idx ? "rotate(-180deg)" : "rotate(0)",
                transition: "0.2s",
                fontSize: "2.4rem",
              }}
            />
          </ListItemButton>

          {/* Steppers */}
          <Collapse in={open === idx} timeout="auto" unmountOnExit>
            <CourseStepperContainer>
              <CourseStepper
                steppersData={data.steppersData}
                currentStep={currentStep}
                handleStepLink={index => handleStepLink(index)}
                position={idx}
                lastItem={lastItem}
                lastStep={lastStep}
              />
            </CourseStepperContainer>
          </Collapse>
        </Box>
      </Paper>
    </NestedListContainer>
  )
}

export default CourseNestedList
