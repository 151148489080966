import { useEffect, useRef } from "react";

import { useFormikContext } from "formik";

const FormObserver = (props) => {
  const { onChange } = props;
  const ctx = useFormikContext();
  const mountUpdate = useRef(true);

  useEffect(() => {
    if (mountUpdate.current) {
      mountUpdate.current = false;
      return;
    }

    if (onChange instanceof Function) {
      onChange(ctx.values, ctx);
    }
  }, [ctx.values]);

  return null;
};

export default FormObserver;
