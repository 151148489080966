import React, { useState, useEffect } from "react"
// routing
import { navigate } from "gatsby"
import useMediaQuery from "@/hooks/useMediaQuery"

import Auth from "@/aws/AmplifyAuthConfigure"
import { API } from "@/aws/AmplifyAuthConfigure"
import { UPDATE_USER_STEPS } from "@/graphql/mutations"

// components && sections
import { CourseNavBar, CourseMenuMobile } from "@/components/CourseNavBar"
import CourseNestedList from "@/components/CourseNestedList"
import CourseContent from "@/course/CourseContent"
import { StartQuizContent } from "@/course/CourseContent"
import { ButtonBase } from "@/components/NotarizationButton"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import loadable from "@loadable/component"
const CourseModal = loadable(() => import("@/components/CourseModal"))
import FeedbackCourseCard from "@/components/FeedbackCourseCard"
// styled
import {
  OuterContainer,
  InnerContainer,
  MenuMobileButton,
} from "./course.style"
// icons
import { MenuIcon } from "@/icons"
// recoil
// import { atom, useRecoilState } from "recoil"

const Course = ({
  user,
  dataCourse,
  onToggleScreen,
  lastItem,
  setLastItem,
  lastStep,
  setLastStep,
  isCompletedCourse,
  setIsCompletedCourse,
}) => {
  const [open, setOpen] = useState(isCompletedCourse ? null : lastItem || 0)
  const [currentItem, setCurrentItem] = useState(lastItem || 0)
  const [currentStep, setCurrentStep] = useState(lastStep || 0)
  const [showQuiz, setShowQuiz] = useState(isCompletedCourse)
  const [showFeedbackModal, setShowFeedbackModal] = useState(
    !user.feedback && lastItem === 7 && lastStep === 0
  )
  const isMD = useMediaQuery({ minWidth: 1024 })
  // console.log(currentItem, currentStep)
  // functionality

  // MENU MOBILE
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  useEffect(() => {
    document.body.style.overflow = isMenuOpen ? "hidden" : null
    return () => {
      document.body.style.overflow = null
    }
  }, [isMenuOpen])
  // MENU MOBILE

  const handleNextStep = activeNextStep => {
    const stepLength = dataCourse.nestedItem[currentItem].steppersData.length
    if (activeNextStep === "activeNextStep") {
      if (currentStep === stepLength - 1) {
        if (currentItem === dataCourse.nestedItem.length - 1) {
          setIsCompletedCourse(true)
          setOpen(null)
          return
        }

        setLastItem(prev => {
          return prev > currentItem ? prev : prev + 1
        })
        setLastStep(prev => {
          return currentItem < lastItem && 0 < lastStep ? prev : 0
        })
        return
      }
      setLastStep(prev => {
        return (prev === stepLength - 1 && prev) ||
          currentItem < lastItem ||
          currentStep < lastStep
          ? prev
          : prev + 1
      })
      return
    }
    if (currentStep === stepLength - 1) {
      if (currentItem === dataCourse.nestedItem.length - 1) {
        setIsCompletedCourse(true)
        setShowQuiz(true)
        setOpen(null)
        return
      }

      setOpen(() => currentItem + 1)
      setCurrentItem(prev => prev + 1)
      setLastItem(prev => {
        return prev > currentItem ? prev : prev + 1
      })
      setCurrentStep(() => {
        if (isCompletedCourse) {
          return 0
        }
        return currentItem + 1 === lastItem ? lastStep : 0
      })
      setLastStep(prev => {
        return currentItem < lastItem && 0 < lastStep ? prev : 0
      })
      return
    }
    setCurrentStep(prev => prev + 1)
    setLastStep(prev => {
      return (prev === stepLength - 1 && prev) ||
        currentItem < lastItem ||
        currentStep < lastStep
        ? prev
        : prev + 1
    })
  }
  const handlePrevStep = () => {
    if (currentStep === 0) {
      const prevStep =
        dataCourse.nestedItem[currentItem - 1].steppersData.length
      setCurrentItem(prev => prev - 1)
      setCurrentStep(prevStep)
      setOpen(currentItem - 1)
    }
    setCurrentStep(prev => prev - 1)
  }

  const handleToggleItem = index => {
    if (showQuiz) setShowQuiz(false)
    if (open === index) {
      return setOpen(null)
    }
    setOpen(index)
    setCurrentItem(index)
    setCurrentStep(() => {
      if (isCompletedCourse) {
        return 0
      }
      return index < lastItem ? 0 : lastStep
    })
  }

  const handleLinkContent = (index1, index2) => {
    if (showQuiz) setShowQuiz(false)
    // if (index2 === currentItem && index1 === currentStep) {
    //   return
    // }
    setCurrentItem(index2)
    setCurrentStep(index1)
    if (!isMD) setIsMenuOpen(false)
  }

  const handleViewQuizContent = () => {
    setShowQuiz(true)
    setOpen(null)
    setIsMenuOpen(false)
  }

  const logOut = async () => {
    try {
      await Auth.signOut()
      navigate("/")
    } catch (error) {
      console.log("error signing out: ", error)
    }
  }

  // UPDATE USER DATA
  useEffect(async () => {
    await API.graphql({
      query: UPDATE_USER_STEPS,
      variables: {
        input: {
          last: JSON.stringify({ item: lastItem, step: 0 }),
        },
      },
    })
    if (!user.feedback && lastItem === 7) {
      setShowFeedbackModal(true)
    }
  }, [lastItem])
  useEffect(async () => {
    await API.graphql({
      query: UPDATE_USER_STEPS,
      variables: {
        input: {
          courseCompleted: isCompletedCourse,
        },
      },
    })
  }, [isCompletedCourse])

  const nestedList = dataCourse.nestedItem.map((data, idx) => {
    return (
      <CourseNestedList
        key={idx}
        data={data}
        idx={idx}
        open={open}
        currentStep={currentStep}
        lastStep={lastStep}
        currentItem={currentItem}
        lastItem={lastItem}
        onToggleItem={() => handleToggleItem(idx)}
        handleStepLink={index => handleLinkContent(index, idx)}
      />
    )
  })
  const quizBtn = (
    <ListItemButton
      className="list-item"
      disabled={!isCompletedCourse && true}
      onClick={handleViewQuizContent}
    >
      <ListItemText
        primaryTypographyProps={{
          fontFamily: "Graphik",
        }}
        sx={{ my: 0 }}
      >
        <span className="primary-text">Quiz</span>
      </ListItemText>
    </ListItemButton>
  )
  const logout = (
    <ButtonBase className="log-out" onClick={logOut}>
      Log out
    </ButtonBase>
  )
  return (
    <div>
      <OuterContainer>
        <CourseNavBar
          user={user}
          step={lastItem}
          isCompletedCourse={isCompletedCourse}
        />
        <InnerContainer>
          {!isMD ? (
            <div className="left-mobile">
              <MenuMobileButton onClick={() => setIsMenuOpen(true)}>
                <MenuIcon />
              </MenuMobileButton>
              <CourseMenuMobile
                isMenuOpen={isMenuOpen}
                onClose={() => setIsMenuOpen(false)}
              >
                <div className="left">
                  {nestedList}
                  {quizBtn}
                  {logout}
                </div>
              </CourseMenuMobile>
            </div>
          ) : (
            <div className="left left-desktop">
              {nestedList}
              {quizBtn}
              {logout}
            </div>
          )}

          <div className="right">
            {isCompletedCourse && showQuiz ? (
              <StartQuizContent onToggleScreen={onToggleScreen} />
            ) : (
              <CourseContent
                contentData={
                  dataCourse.nestedItem[currentItem].steppersData[currentStep]
                    .course_content
                }
                onNextStep={handleNextStep}
                onPrevStep={handlePrevStep}
                lastItem={lastItem}
                lastStep={lastStep}
                currentItem={currentItem}
                currentStep={currentStep}
                isCompletedCourse={isCompletedCourse}
              />
            )}
          </div>
        </InnerContainer>
        <CourseModal showModal={showFeedbackModal}>
          <FeedbackCourseCard
            onCloseModal={() => setShowFeedbackModal(false)}
          />
        </CourseModal>
      </OuterContainer>
    </div>
  )
}

export default Course
