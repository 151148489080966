import styled from "styled-components"
export const FeedbackCourseCardContainer = styled.div`
  width: calc(100vw - 3.2rem);
  max-width: 40.4rem;
  padding: 3.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #161617;
  box-shadow: 0px 3.24752px 44.6535px rgba(0, 0, 0, 0.25);
  color: #fff;

  h3 {
    text-align: center;
    margin-bottom: 0.8rem;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 20px;
    span {
      text-transform: uppercase;
      color: #ffc700;
    }
  }
  p {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 1.2rem;
    line-height: 13px;
    color: #cdcdcd;
  }

  .MuiRating-sizeMedium {
    svg {
      width: 3rem;
      height: 3rem;
    }
  }
  .MuiRating-iconEmpty {
    color: #ffc700;
  }

  & > div {
    display: flex;
    align-self: stretch;
  }
  button {
    flex: 1;
    height: 3.7rem;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 13px;
    border-radius: 2px;
  }
  button:first-of-type {
    border: 1px solid #fff;
    margin-right: 1rem;
  }
  button:last-of-type {
    background-color: #ffc700;
    color: #161617;
  }

  textarea {
    width: 100%;
    height: 6.9rem;
    padding: 1rem;
    margin: 2.5rem 0;
    resize: none;
    border-radius: 1.62376px;
    font-family: "Graphik", sans-serif;
    font-size: 1.2rem;
    line-height: 13px;
    color: #222;
  }
  textarea:focus {
    outline: none !important;
  }

  .loading {
    background-color: #999 !important;
    transition: 0.3s ease-in;
  }

  @media screen and (min-width: 1024px) {
    width: 100vw;
    box-shadow: 0px 4px 55px rgba(0, 0, 0, 0.25);
    h3 {
      margin-bottom: 1rem;
      font-size: 2.3rem;
      line-height: 25px;
    }
    p {
      margin-bottom: 2rem;
      font-size: 1.6rem;
      line-height: 18px;
    }
    button {
      height: 4.6rem;
      font-size: 1.4rem;
      line-height: 15px;
    }
    textarea {
      height: 8.6rem;
      font-size: 1.4rem;
    }
  }
`
