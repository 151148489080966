import React, {useState, useEffect} from "react"

// import components & elem
import QuizIndicator from "../QuizIndicator"
import MessageText from "../MessageText"
import ButtonGroup from "../ButtonGroup"
import IncorrectAnswer from "../IncorrectAnswer"
import { ButtonBase } from "@/components/NotarizationButton"

// hooks 
import { useScrollTop } from "@/hooks/useScrollTop"
// icons
import {ArrowLeftIcon } from "@/icons"

import styled from "styled-components"
const ResultsWrap = styled.div`
  max-width: 1432px;
  margin: 0 auto;
  padding: 94px 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100%;
  color: #fff;

  @media only screen and (max-width: 768px) {
    padding-top: 43px;
    padding-bottom: 43px;
  }
`
const ScrollTopBtn = styled(ButtonBase)`
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  width: 50px;
  height: 50px;
  background: #ffffff;
  border-radius: 50%;
  svg {
    display: inline;
    transform: rotate(90deg);
    path {
      fill: #161617;
    }
  }
`

const resultDetails = {
  noPassed: {
    title: "Oops!",
    subTitle:
      "You have not passed the test. Below are written the questions which you answered incorrectly. You have the option to re-take the test. We recommend that you review the lessons again before taking the test.",
    buttons: [
      { text: "Try Again", classes: "primary", func: "tryAgainHandle" },
      {
        text: "Go to the Lessons",
        classes: "secondary",
        func: "goToCourseHandle",
      },
    ],
  },
  passed: {
    title: "Congratulations!",
    subTitle:
      "You completed the Course. We are glad to assist you in this process. Find out what the next steps are on our website. Best of luck. </br> Ronary Team.",
    buttons: [
      {
        text: "Download certificate",
        classes: "primary",
        func: "downloadCertificateHandle",
      },
      {
        text: "Go to the Lessons",
        classes: "secondary",
        func: "goToCourseHandle",
      },
    ],
  },
}

const QuizResults = ({ data, onTryAgain, onToggleScreen, user }) => {
  const [showButton, handlescrollTop] = useScrollTop(false, 300)

  const handlePercent = number => {
    return (number * 100) / data.length
  }

  const handleAnswers = data => {
    const correctNumberAnswer = data.filter(
      item => item.isCorrect === item.answer
    ).length

    const incorrectAnswerData = data
      .map((item, idx) => ({ ...item, index: idx + 1 }))
      .filter(item => item.isCorrect !== item.answer)

    return {
      percent: handlePercent(correctNumberAnswer),
      correctNumberAnswer,
      totalNumberQuestion: data.length,
      indicatorColor: handlePercent(correctNumberAnswer) >= 70 ? true : false,
      passed: handlePercent(correctNumberAnswer) >= 70 ? "passed" : "noPassed",
      cardDetails: incorrectAnswerData,
    }
  }
  const transformData = handleAnswers(data)

  return (
    <ResultsWrap>
      <QuizIndicator {...transformData} />
      <MessageText messageData={resultDetails[transformData.passed]} />
      <ButtonGroup
        btn={resultDetails[transformData.passed].buttons}
        onTryAgain={onTryAgain}
        onToggleScreen={onToggleScreen}
      />
      <IncorrectAnswer
        cardDetails={transformData.cardDetails}
        percent={transformData.percent}
      />
      {showButton && (
        <ScrollTopBtn onClick={handlescrollTop}>
          <ArrowLeftIcon />
        </ScrollTopBtn>
      )}
    </ResultsWrap>
  )
}

export default QuizResults
