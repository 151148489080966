import { forwardRef } from "react";

import { useField } from "formik";
import styled from "styled-components";

const SurveyFormRadioFieldRoot = styled.label`
  padding: 28px 25px;
  background: ${(p) => (p.active ? "#2E3134" : "#212225")};
  border: 1px solid ${(p) => (p.active ? p.theme.colors.primary : "#212225")};
  color: #fff;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  max-width: 600px;

  input {
    display: none;
  }

  @media (max-width: 1024px), (max-height: 1200px) {
    padding: 10px 24px;
  }
`;

const SurveyFormRadioFieldCheckIcon = styled.div`
  flex-shrink: 0;
  position: relative;
  border: 1px solid #565863;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04), 0 0.5px 2.3px rgba(0, 0, 0, 0.03);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 12px;

  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: linear-gradient(147.09deg, #ffc700 12.86%, #ef9f27 86.07%);
    opacity: ${(p) => +p.active};
  }
`;

const SurveyFormRadioFieldLabel = styled.div`
  line-height: 24px;

  @media (max-width: 1024px) {
    font-size: 14px;
  }
`;

const SurveyFormRadioFieldText = styled.div`
  color: #828282;
  font-size: 14px;
  line-height: 1.2;

  @media (max-width: 1024px) {
    font-size: 10px;
  }
`;

const SurveyFormRadioField = forwardRef(
  ({ label, name, value, text, ...rest }, ref) => {
    const [field, meta] = useField({ name, type: "radio" });

    return (
      <SurveyFormRadioFieldRoot active={meta.value === value}>
        <input type="radio" ref={ref} {...rest} {...field} value={value} />
        <SurveyFormRadioFieldCheckIcon active={meta.value === value} />
        <SurveyFormRadioFieldLabel>
          <div>{label}</div>
          {text && (
            <div css="margin-top: 6px;">
              <SurveyFormRadioFieldText>{text}</SurveyFormRadioFieldText>
            </div>
          )}
        </SurveyFormRadioFieldLabel>
      </SurveyFormRadioFieldRoot>
    );
  }
);

export default SurveyFormRadioField;
