import styled from "styled-components"

import { ButtonBase } from "@/components/NotarizationButton"

export const Container = styled.div`
  /* MOBILE */
  position: relative;
  color: #25272b;
  margin-bottom: 5rem;

  .header {
    font-family: "Graphik";
    color: #25272b;
    margin-bottom: 3rem;
    display: flex;
    flex-flow: row nowrap;
    gap: 1rem;
    justify-content: space-between;
    align-items: flex-start;
  }
  .title {
    line-height: 120%;
    letter-spacing: -0.03em;
    font-weight: 400;
  }
  .title-primary {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
  }
  .title-secondary {
    font-size: 2.4rem;
    font-weight: 500;
  }
  .btn-control-group {
    display: flex;
    gap: 1rem;
  }

  .prev-less {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row-reverse;
    border: 1px solid #c4c4c4;
    span {
      color: #c4c4c4;
      margin-left: 1.33rem;
      font-weight: 500;
    }
    svg {
      path {
        fill: #c4c4c4;
      }
    }
  }
  .next-less {
    flex: 1 1 auto;
    display: flex;
    background-color: #c4c4c4;
    span {
      color: #fff;
      margin-right: 1.33rem;
      font-weight: 500;
    }
    svg {
      path {
        fill: #fff;
      }
    }
  }
  .prev-active {
    border: 1px solid #161617;
    span {
      color: #161617;
    }
    svg {
      path {
        fill: #161617;
      }
    }
  }
  .next-active {
    background-color: #161617;
  }
  .desktop-btn {
    display: none;
  }

  .video-content {
    position: relative;
    padding-top: 56.25%;
    margin-bottom: 3.5rem;

    .frame {
      position: absolute;
      top: 0;

      iframe {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .attachments-content {
    margin-bottom: 3rem;
  }
  .attachments-title,
  .description-title {
    font-size: 1.6rem;
    line-height: 120%;
    font-weight: 500;
    letter-spacing: -0.02em;
    margin-bottom: 1.5rem;
  }
  .attachments-links {
    font-size: 1.4rem;
    line-height: 150%;
    letter-spacing: -0.02em;
    text-decoration-line: underline;
    display: flex;
    flex-direction: column;
    margin-bottom: 0.6rem;
    a {
      align-self: flex-start;
      line-height: 100%;
      margin-bottom: 1rem;
    }
  }
  li {
    list-style-position: inside;
  }

  @media only screen and (min-width: 768px) {
    .title-primary {
      font-size: 1.6rem;
    }
    .title-secondary {
      font-size: 2.6rem;
    }
  }

  /* DESKTOP */
  @media only screen and (min-width: 1024px) {
    margin-bottom: 16rem;
    .header {
      margin-bottom: 5rem;
    }
    .mobile-btn {
      display: none;
    }
    .desktop-btn {
      display: flex;
    }
    .video-content {
      padding-top: 56%;
      margin-bottom: 4rem;
    }
    .title-primary {
      /* margin-bottom: 1.1rem; */
    }
    .attachments-content {
      margin-bottom: 6rem;
    }
    .attachments-title,
    .description-title {
      font-size: 2.2rem;
      margin-bottom: 2rem;
    }
    .attachments-links {
      font-size: 1.8rem;
      a {
        margin-bottom: 2rem;
      }
    }
  }
  @media only screen and (min-width: 1440px) {
    .title-primary {
      font-size: 1.8rem;
      margin-bottom: 1.1rem;
    }
    .title-secondary {
      font-size: 2.8rem;
    }
  }
`
export const CustomButton = styled(ButtonBase)`
  /* MOBILE */
  height: 4rem;

  border: none;
  border-radius: 2px;
  margin-bottom: 4rem;
  transition: 0.3s;

  span {
    font-size: 1.2rem;
    line-height: 100%;
    letter-spacing: -0.03em;
    position: relative;
    top: 0.1rem;
  }
  svg {
    width: 1.333rem;
    height: 1rem;
  }
  @media only screen and (min-width: 375px) {
    span {
      font-size: 1.4rem;
    }
  }
  @media only screen and (min-width: 768px) {
  }
  /* DESKTOP */
  @media only screen and (min-width: 1024px) {
    width: 5rem;
    height: 5rem;
    margin-bottom: 0;

    span {
      display: none;
    }
    svg {
      width: 16px;
      height: 12px;
    }
  }
  @media only screen and (min-width: 1440px) {
    width: 5.6rem;
    height: 5.6rem;
  }
`
export const DescriptionContainer = styled.div`
  overflow-wrap: break-word;
`
export const ScrollTopBtn = styled(ButtonBase)`
  position: fixed;
  bottom: 5rem;
  right: 2rem;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #161617;
  svg {
    display: inline;
    transform: rotate(90deg);
  }
`
