import React from "react";
import styled from "styled-components";

const MessageTextContainer = styled.div`
  max-width: 969px;
  margin-bottom: 80px;
  text-align: center;

  h2 {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 15px;
    line-height: 100%;
  }
  p {
    font-weight: 300;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #747a82;
  }

  @media (min-width: 481px) {
    h2 {
      font-size: 35px;
    }
    p {
      font-size: 18px;
    }
  }

  @media (min-width: 769px) {
    margin-bottom: 60px;

    h2 {
      font-size: 50px;
    }
    p {
      font-size: 24px;
      line-height: 150%;
    }
  }
`

const MessageText = ({ messageData: { title, subTitle } }) => {
  return (
    <MessageTextContainer>
      <h2>{title}</h2>
      <p dangerouslySetInnerHTML={{ __html: subTitle }}></p>
    </MessageTextContainer>
  )
};

export default MessageText;
