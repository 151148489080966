import React, { useState, useEffect } from "react"

import loadable from "@loadable/component"
const ReactPlayer = loadable(() => import("react-player"))

import useMediaQuery from "@/hooks/useMediaQuery"
import { BigArrowRightIcon } from "@/icons"
import preview from "../../images/CoursePreview.webp"

import {
  Container,
  CustomButton,
  DescriptionContainer,
} from "./CourseContent.style"

const ControlBtn = ({ label = "click", classes, rotate = 0, ...props }) => {
  return (
    <CustomButton className={`${classes}`} {...props}>
      <span>{label}</span>
      <BigArrowRightIcon style={{ transform: `rotate(${rotate}deg)` }} />
    </CustomButton>
  )
}

const CourseContent = ({
  contentData,
  onNextStep,
  onPrevStep,
  lastItem,
  lastStep,
  currentItem,
  currentStep,
  isCompletedCourse,
}) => {
  const isMD = useMediaQuery({ minWidth: 1024 })
  // const [showButton, handleScrollTop] = useScrollTop(false, 300)
  const [watchComplete, setWatchComplete] = useState(false)
  const handleWatchComplete = ({ played }) => {
    if (played >= 0.8 && !watchComplete) {
      setWatchComplete(true)
      onNextStep("activeNextStep")
    }
  }

  useEffect(() => {
    if (isCompletedCourse) {
      setWatchComplete(true)
      return
    }
    currentItem === lastItem && currentStep === lastStep
      ? setWatchComplete(false)
      : setWatchComplete(true)
  }, [currentItem, currentStep])

  return (
    <Container>
      <div className="header">
        <div style={{ flex: "1 1 100%" }}>
          <h2 className="title title-primary">{contentData.contentTitle}</h2>
          <h3 className="title title-secondary">
            {contentData.contentSubtitle}
          </h3>
        </div>
        {isMD && (
          <div className="btn-control-group desktop-btn">
            <ControlBtn
              classes={`prev-less ${
                currentItem || currentStep ? "prev-active" : " "
              }`}
              rotate="180"
              onClick={onPrevStep}
              disabled={!(currentItem || currentStep)}
            />
            <ControlBtn
              classes={`next-less ${watchComplete && "next-active"}`}
              onClick={onNextStep}
              disabled={!watchComplete && true}
            />
          </div>
        )}
      </div>

      <div className="video-content">
        <ReactPlayer
          key={contentData.videoLink}
          light={preview}
          playing
          controls
          onProgress={handleWatchComplete}
          url={contentData.videoLink}
          width="100%"
          height="100%"
          className="frame"
          playsinline={true}
          config={{
            vimeo: {
              playerOptions: { playsinline: true },
            },
          }}
        />
      </div>

      {!isMD && (
        <div className="btn-control-group mobile-btn">
          <ControlBtn
            label="Previous Step"
            classes={`prev-less ${
              currentItem || currentStep ? "prev-active" : " "
            }`}
            rotate="180"
            onClick={onPrevStep}
            disabled={!(currentItem || currentStep)}
          />
          <ControlBtn
            label="Next Lesson"
            classes={`next-less ${watchComplete && "next-active"}`}
            onClick={onNextStep}
            disabled={!watchComplete && true}
          />
        </div>
      )}

      {contentData.attachments.length ? (
        <div className="attachments-content">
          <h4 className="attachments-title">Reference Links</h4>
          <div className="attachments-links">
            {contentData.attachments.map(({ title, link }, index) => (
              <a key={index} href={link} target="_blank">
                {title}
              </a>
            ))}
          </div>
        </div>
      ) : null}

      <div className="description-content">
        <h4 className="description-title">Description</h4>
        <DescriptionContainer
          dangerouslySetInnerHTML={{ __html: contentData.contentDescription }}
        />
      </div>
      {/* {showButton && (
        <ScrollTopBtn onClick={handleScrollTop}>
          <ArrowLeftIcon />
        </ScrollTopBtn>
      )} */}
    </Container>
  )
}

export default CourseContent
