import * as React from "react"

import Box from "@mui/material/Box"
import Step from "@mui/material/Step"
import StepButton from "@mui/material/StepButton"
import StepLabel from "@mui/material/StepLabel"
import Stepper from "@mui/material/Stepper"
import { color, styled } from "@mui/system"

import { WatchIcon, CheckCourseIcon } from "@/icons"

const RootContainer = styled(Box)({
  // main container stepper style
  ".css-14sza3e-MuiStepLabel-root": {
    padding: "0",
  },

  // Step Button
  ".MuiStepButton-root": {
    display: "block",
  },

  // text style
  ".css-qivjh0-MuiStepLabel-label.Mui-completed": {
    fontWeight: 400,
  },
  ".css-qivjh0-MuiStepLabel-label.Mui-active": {
    fontWeight: 400,
  },
  ".labelStepper": {
    fontSize: "1.2rem",
    fontFamily: "Graphik",
    lineHeight: "120%",
    fontWeight: 400,
    letterSpacing: "-0.03em",
    color: "#25272B",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    position: "relative",
    top: "2px",
  },
  ".labelDuration": {
    minWidth: "82px",
    display: "flex",
    alignItems: "flex-start",
    marginLeft: "0.6rem",
    color: "#9B9DA4",
    fontSize: "1.2rem",
    lineHeight: "120%",
    span: {
      fontWeight: 400,
    },
    svg: {
      width: "10px",
      marginRight: "5px",
    },
  },
  ".selected-step": {
    fontWeight: 500,
  },
  // unite line style
  ".css-8t49rw-MuiStepConnector-line": {
    height: "26px",
    borderColor: "#D4D6DD",
  },
  ".css-52tf1x": {
    marginLeft: "1rem",
  },

  // step icon style
  ".css-vnkopk-MuiStepLabel-iconContainer": {
    padding: "0.8rem 0.8rem 0.8rem 0",
  },
  ".MuiStepIcon-root": {
    border: "1px solid #D4D6DD",
    borderRadius: "100%",
    fontSize: "2.1rem",
    color: "#fff",
  },

  // MEDIA QUERIES //////////////////////////
  "@media only screen and (min-width: 768px)": {
    ".labelStepper": {
      fontSize: "1.3rem",
    },
    ".labelDuration": {
      fontSize: "1.3rem",
      svg: {
        width: "14px",
      },
    },
  },
  "@media only screen and (min-width: 1024px)": {
    ".labelStepper": {
      fontSize: "1.6rem",
    },
    ".labelDuration": {
      fontSize: "1.4rem",
    },
    ".css-8t49rw-MuiStepConnector-line": {
      height: "32px",
    },
  },
})

const CourseStepper = ({
  steppersData,
  currentStep,
  lastStep,
  position,
  lastItem,
  handleStepLink,
}) => {
  const stepComplete = position < lastItem ? steppersData.length - 1 : lastStep

  return (
    <RootContainer sx={{ maxWidth: "100%" }}>
      <Stepper activeStep={stepComplete} orientation="vertical">
        {steppersData.map((step, index) => {
          const activeStep = position < lastItem || index <= lastStep
          return (
            <Step key={step.labelStepper}>
              <StepButton
                icon={activeStep ? <CheckCourseIcon /> : null}
                onClick={
                  activeStep
                    ? () => {
                        handleStepLink(index)
                      }
                    : null
                }
              >
                <StepLabel>
                  <span
                    className={`labelStepper ${
                      index === currentStep && "selected-step"
                    }`}
                  >
                    <span>{step.labelStepper}</span>
                    <span className="labelDuration">
                      <WatchIcon />
                      <span>{step.stepDuration} min</span>
                    </span>
                  </span>
                </StepLabel>
              </StepButton>
            </Step>
          )
        })}
      </Stepper>
    </RootContainer>
  )
}

export default CourseStepper
