import React from "react"

export const useScrollTop = (defaultValue, params = 150) => {
  const [showButton, setShowButton] = React.useState(defaultValue)
  const handleShowbutton = () => {
    if (window.scrollY > params) {
      setShowButton(true)
    } else setShowButton(false)
  }
  const handlescrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }
  React.useEffect(() => {
    window.addEventListener("scroll", handleShowbutton)
    return () => removeEventListener("scroll", handleShowbutton)
  }, [])

  return [showButton, handlescrollTop]
}