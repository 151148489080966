import React, { useState, useRef } from "react"
import { Rating } from "@mui/material"
import { FeedbackCourseCardContainer } from "./FeedbackCourseCard.style"
import { ButtonBase } from "../NotarizationButton"
import { API } from "@/aws/AmplifyAuthConfigure"
import { SET_USER_FEEDBACK } from "@/graphql/mutations"

const FeedbackCourseCard = ({ onCloseModal }) => {
  const [rating, setRating] = useState(null)
  const [loading, setLoading] = useState(false)
  const messageRef = useRef(null)
  const handleSubmit = async () => {
    if (rating) {
      setLoading(true)

      try {
        await API.graphql({
          query: SET_USER_FEEDBACK,
          variables: {
            input: {
              rating,
              message: messageRef.current.value,
              feedback: true,
            },
          },
        })
      } catch (e) {
        console.log("Something went wrong", e)
      }
      setLoading(false)
      onCloseModal()
    }
  }

  return (
    <FeedbackCourseCardContainer>
      <h3>
        Take <span>free</span> session in the Ronary App
      </h3>
      <p>Left a feedback about the course and take free session.</p>
      <Rating
        name="simple-controlled"
        value={rating}
        onChange={(event, newValue) => {
          setRating(newValue)
        }}
      />
      <textarea
        ref={messageRef}
        placeholder="Please enter your comment here"
      ></textarea>
      <div>
        <ButtonBase onClick={onCloseModal}> No thanks </ButtonBase>
        <ButtonBase
          onClick={handleSubmit}
          className={`${loading ? "loading" : ""}`}
          disabled={loading ? true : false}
        >
          {" "}
          Send{" "}
        </ButtonBase>
      </div>
    </FeedbackCourseCardContainer>
  )
}

export default FeedbackCourseCard
