import styled from "styled-components";

import SurveyFormRadioField from "../fields/SurveyFormRadioField";

const SurveyFormRadioRoot = styled.div`
  & > label {
    margin-bottom: 16px;

    @media (max-width: 1024px) {
      margin-bottom: 10px;
    }
  }
`;

const SurveyFormRadio = ({ name, options }) => (
  <SurveyFormRadioRoot>
    {options.map((option) => (
      <SurveyFormRadioField key={option.value} name={name} {...option} />
    ))}
  </SurveyFormRadioRoot>
);

export default SurveyFormRadio;
