import React, { useState } from "react"

import Card from "../Card"
import ButtonsControlSlider from "../ButtonsControlSlider"
import styled from "styled-components"
import useMediaQuery from "@/hooks/useMediaQuery"

const IncorrectAnswerContainer = styled.div`
  width: 100%;
  h2 {
    text-align: center;
    font-weight: normal;
    font-size: 30px;
    line-height: 120%;
    letter-spacing: -0.02em;
    margin-bottom: 13px;
  }
  .show-more {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 20px;
    border: none;
    background: none;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: -0.02em;
    border-bottom: 1px solid #fff;
    color: #fff;
    text-transform: capitalize;
    display: none;
  }
  .slider-container {
    overflow: hidden;
    display: block;
  }
  @media (max-width: 768px) {
    h2 {
      font-size: 20px;
      margin-bottom: 25px;
    }
    .show-more {
      display: block;
    }
    .slider-container {
      display: none;
    }
  }
`
const CardsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 2%;
  width: 100%;
  transform: translateX(-0%);
  transition: transform 0.3s;
  /* slider */

  /* show more */
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 15px;
  }
`
const IncorrectAnswer = ({ cardDetails, percent }) => {
  if (Number(percent) === 100) {
    return null
  }

  const isMD = useMediaQuery({ maxWidth: 768 })
  const [showMore, setShowMore] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)

  const isLG = useMediaQuery({ minWidth: 769 })
  const isXL = useMediaQuery({ minWidth: 993 })
  const isXXL = useMediaQuery({ minWidth: 1241 })
  const [maxIndex, setMaxIndex] = useState(0)
  function handleMaxIndex(idx, value) {
    const tmp = Math.ceil(cardDetails.length / value - 1)
    if (tmp !== idx) {
      setMaxIndex(tmp)
    }
  }
  if (isXXL) {
    handleMaxIndex(maxIndex, 4)
  } else if (isXL) {
    handleMaxIndex(maxIndex, 3)
  } else if (isLG) {
    handleMaxIndex(maxIndex, 2)
  }

  const numberOfItems = showMore ? cardDetails.length : 2
  const showMoreEnabled = cardDetails.length > 2 && numberOfItems === 2
  const handleActiveIndex = newIndex => {
    if (activeIndex <= 0) {
      setActiveIndex(0)
    }
    setActiveIndex(prev => prev + newIndex)
  }

  return (
    <IncorrectAnswerContainer>
      <h2>Incorrect Answer</h2>

      {/* SHOW MORE */}
      {isMD ? (
        <div className="showMore-container">
          <CardsContainer>
            {cardDetails.slice(0, numberOfItems).map(details => {
              return <Card key={details.name} {...details} />
            })}
          </CardsContainer>
          {showMoreEnabled ? (
            <button onClick={() => setShowMore(true)} className="show-more">
              show more
            </button>
          ) : null}
        </div>
      ) : (
        <div className="slider-container">
          <ButtonsControlSlider
            onActiveIndex={handleActiveIndex}
            activeIndex={activeIndex}
            maxIndex={maxIndex}
          />
          <CardsContainer
            style={{ transform: `translateX(-${activeIndex * 102}%)` }}
          >
            {cardDetails.map((details, idx) => {
              return <Card key={idx} {...details} />
            })}
          </CardsContainer>
        </div>
      )}
    </IncorrectAnswerContainer>
  )
}

export default IncorrectAnswer
