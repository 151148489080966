import React from "react"

import styled from "styled-components"

import { ErrIcon } from "@/icons"

const CardContainer = styled.div`
  padding: 16px;
  min-width: 23.5%;
  width: 100%;
  height: 100%;
  background: #212225;
  border-radius: 2px;
  line-height: 170%;

  .question {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 15px;
  }
  .incorrect-answer {
    font-size: 14px;
    letter-spacing: -0.02em;
    line-height: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    span {
      position: relative;
      top: 1px;
    }
    svg {
      margin-right: 10px;
    }
  }
  .lesson-chapter {
    font-size: 12px;
    letter-spacing: -0.02em;
    color: #686a6d;
  }
  @media only screen and (max-width: 1240px) {
    min-width: 32%;
  }
  @media only screen and (max-width: 992px) {
    min-width: 49%;
  }
`
const Card = ({ children, title, answer, index }) => {
  return (
    <CardContainer>
      <h3 className="question">
        {index}. {title}
      </h3>
      <p className="incorrect-answer">
        <ErrIcon /> <span>{answer}</span>
      </p>
      {children}
    </CardContainer>
  )
}

export default Card
