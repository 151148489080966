import React from "react"
import Avatar from "@/components/Avatar"
import Progress from "@/components/Progress"
import useMediaQuery from "@/hooks/useMediaQuery"
import { CourseLogoIcon } from "@/icons"
import styled from "styled-components"
const Header = styled.header`
  padding: 0 1.6rem;
  background-color: #18191b;
  position: relative;

  nav {
    display: flex;
    width: 100%;
    height: 6.3rem;
    justify-content: space-between;
    align-items: center;
  }
  .mobile-nav {
    display: flex;
    flex-direction: row-reverse;
  }
  .desktop-nav {
    display: none;
  }

  .logo {
    color: #fff;
    font-size: 1.3rem;
  }
  .author {
    min-width: 220px;
    display: flex;
    justify-content: space-between;
  }
  @media only screen and (min-width: 1024px) {
    padding: 0 6rem;
    nav {
      height: 8rem;
    }
    .mobile-nav {
      display: none;
    }
    .desktop-nav {
      display: flex;
    }
  }
`

const CourseNavBar = ({ user, step, isCompletedCourse }) => {
  const isMD = useMediaQuery({ minWidth: 1024 })

  // AVATAR comp LOGIC
  const data = { firstName: user.firstName, lastName: user.lastName }
  const initial = Object.keys(data).reduce(
    (res, item) => (res += `${data[item].slice(0, 1)}`),
    ""
  )

  // PROGRESS comp LOGIC
  const progressInfo = isCompletedCourse ? 100 : (step * 100) / user.allStep

  return (
    <Header>
      {!isMD ? (
        <nav className="mobile-nav" style={{ justifyContent: "flex-start" }}>
          <Avatar initial={initial} />
          <Progress name={data} progressInfo={progressInfo} />
        </nav>
      ) : (
        <nav className="desktop-nav">
          <div className="logo">
              <CourseLogoIcon />
          </div>
          <div className="author">
            <Avatar initial={initial} />
            <Progress name={data} progressInfo={progressInfo} />
          </div>
        </nav>
      )}
    </Header>
  )
}

export default CourseNavBar
