import React from "react"
import { ButtonBase } from "@/components/NotarizationButton"
import styled from "styled-components"
const StartQuizContentContainer = styled.div`
  .title {
    margin-bottom: 1.5rem;
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    color: #161617;
  }
  .desc {
    width: 100%;
    font-weight: normal;
    font-size: 16px;
    color: #25272b;
    line-height: 150%;
    letter-spacing: -0.02em;
    margin-bottom: 6rem;
  }
  @media only screen and (min-width: 480px) {
    .desc {
      max-width: 557px;
    }
  }
  @media only screen and (min-width: 1024px) {
    .desc {
      font-size: 18px;
      margin-bottom: 5.5rem;
    }
  }
`
const StartQuizBtn = styled(ButtonBase)`
  width: 100%;
  height: 46px;
  border-radius: 2px;
  background: #ffc700;

  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: -0.025em;
  color: #18191b;

  @media only screen and (min-width: 480px) {
    width: 250px;
  }
  @media only screen and (min-width: 1024px) {
    height: 56px;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: -0.02em;
  }
`

const StartQuizContent = ({ onToggleScreen }) => {
  return (
    <StartQuizContentContainer>
      <h1 className="title">Congratulation!</h1>
      <p className="desc">
        You have completed the theoretical part of the course. Now you will have
        the opportunity to go through to answer questions about the material
        covered. If you are ready you can start quiz.
      </p>
      <StartQuizBtn onClick={onToggleScreen}>Start Quiz</StartQuizBtn>
    </StartQuizContentContainer>
  )
}

export default StartQuizContent
