import React from "react"

import { CloseIcon, CourseLogoBlackIcon as Logo } from "@/icons"

import { AnimatePresence, motion } from "framer-motion"

import { ButtonBase } from "@/components/NotarizationButton"

import styled from "styled-components"
const MenuContainer = styled(motion.div)`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  z-index: 999;
  padding: 1.6rem 1.6rem;
  background: #fff;
  overflow: auto;

  .header {
    margin-bottom: 4.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`
const CloseIconButton = styled(ButtonBase)`
  svg {
    path {
      fill: #161617;
    }
  }
  font-size: 1.4rem;
  padding: 0.5rem;
`

const CourseMenuMobile = ({ children, isMenuOpen, onClose }) => {
  return (
    <AnimatePresence>
      {isMenuOpen && (
        <MenuContainer
          initial={{ x: "-100%" }}
          animate={{ x: 0 }}
          exit={{ x: "-100%" }}
          transition={{ ease: "easeInOut" }}
        >
          <header className="header">
            <div className="logo">
                <Logo />
            </div>
            <CloseIconButton
              onClick={onClose}
              as={motion.button}
              initial={{ rotate: -180 }}
              animate={{ rotate: 0 }}
              exit={{ rotate: -180 }}
            >
              <CloseIcon />
            </CloseIconButton>
          </header>
          {children}
        </MenuContainer>
      )}
    </AnimatePresence>
  )
}

export default CourseMenuMobile
