import React from "react"
import styled from "styled-components"
import { ArrowRightIcon, ArrowLeftIcon } from "@/icons"
import { ButtonBase } from "@/components/NotarizationButton"
const ButtonsControlContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 19px;
  margin-bottom: 35px;
  border-bottom: 2px solid #212225;

  .prev {
    margin-right: 23px;
  }
  .next {
  }
  
  .in-active {
    path {
      transition: .2s ease-in;
      fill: #686a6d;
    }
  }
  .active {
    path {
      transition: .2s ease-in;
      fill: #fff;
    }
  }
`

const CustomButton = styled(ButtonBase)`
  svg {
    width: 11px;
    height: 18px;
  }
`

const ButtonsControlSlider = ({ onActiveIndex, activeIndex, maxIndex }) => {

const prevDisabled = activeIndex === 0 ? true : false
const nextDisabled = activeIndex >= maxIndex ? true : false 
  return (
    <ButtonsControlContainer>
      {/* prev */}
      <CustomButton
        className={`prev ${prevDisabled ? "in-active" : "active"}`}
        disabled={prevDisabled}
        onClick={() => onActiveIndex(-1)}
      >
        <ArrowLeftIcon />
      </CustomButton>
      {/* next */}
      <CustomButton
        className={`prev ${nextDisabled ? "in-active" : "active"}`}
        disabled={nextDisabled}
        onClick={() => onActiveIndex(1)}
      >
        <ArrowRightIcon />
      </CustomButton>
    </ButtonsControlContainer>
  )
}

export default ButtonsControlSlider
