import styled from "styled-components";

import SurveyFormRatingField from "../fields/SurveyFormRatingField";

const SurveyFormRatingRoot = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -12px;

  & > label {
    margin: 0 12px;

    @media (max-width: 1024px) {
      margin: 0;
    }
  }

  @media (max-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    column-gap: 12px;
    row-gap: 24px;
    margin: 0;
  }
`;

const SurveyFormRating = ({ name, options }) => (
  <SurveyFormRatingRoot>
    {options.map((option) => (
      <SurveyFormRatingField key={option.value} name={name} {...option} />
    ))}
  </SurveyFormRatingRoot>
);

export default SurveyFormRating;
