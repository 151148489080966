import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"

import Auth from "@/aws/AmplifyAuthConfigure"
import { API } from "@/aws/AmplifyAuthConfigure"
import { GET_DATA_COURSE } from "@/graphql/fragments"

import CustomHelmet from "@/components/CustomHelmet/CustomHelmet"
import Loader from "@/components/Loader"
import ErrorBoundry from "@/components/ErrorBoundry/ErrorBoundry"

export const withAuth = Page => props => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [ronaryCourse, setRonaryCourse] = useState(null)

  useEffect(async () => {
    const jwtToken = await Auth.currentSession()
      .then(session => session.getAccessToken().getJwtToken())
      .catch(error => console.log(error))
    if (!jwtToken) {
      navigate("/login")
      return
    }
    try {
      const {
        data: { getCourse },
      } = await API.graphql({
        query: GET_DATA_COURSE,
      })
      const res = JSON.parse(getCourse)
      if (res.statusCode !== 200) throw res.body

      setRonaryCourse(res.body)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      setError(true)
    }
  }, [])

  const errorBoundry = error && <ErrorBoundry />
  const loader = loading && <Loader />
  const renderCourse = !(error || loading) && ronaryCourse && (
    <Page {...props} data={ronaryCourse.data} user={ronaryCourse.user} />
  )

  return (
    <>
      <CustomHelmet title="Ronary Course"></CustomHelmet>
      {errorBoundry}
      {loader}
      {renderCourse}
    </>
  )
}
