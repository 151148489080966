import React, { useState } from "react"

import { withAuth } from "@/hocs/withAuth"
import Course from "@/course"
import Quiz from "@/quiz"

const CoursePage = ({ data: { dataCourse, dataQuiz }, user }) => {
  const [showCourse, setShowCourse] = useState(true)
  const [lastItem, setLastItem] = useState(user?.last.item || 0)
  const [lastStep, setLastStep] = useState(user?.last.step || 0)
  const [isCompletedCourse, setIsCompletedCourse] = useState(
    user?.courseCompleted || false
  )


  return (
    <>
      {showCourse ? (
        <Course
          dataCourse={dataCourse}
          user={user}
          onToggleScreen={() => setShowCourse(false)}
          lastItem={lastItem}
          setLastItem={val => setLastItem(val)}
          lastStep={lastStep}
          setLastStep={val => setLastStep(val)}
          isCompletedCourse={isCompletedCourse}
          setIsCompletedCourse={val => setIsCompletedCourse(val)}
        />
      ) : (
        <Quiz
          dataQuiz={dataQuiz.questions}
          onToggleScreen={() => setShowCourse(true)}
        />
      )}
    </>
  )
}

export default withAuth(CoursePage)
