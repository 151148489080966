import React from "react"

import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import styled from "styled-components"

const IndicatorContainer = styled.div`
  position: relative;
  margin-bottom: 80px;
  .text {
    text-align: center;
    width: 100px;
    font-size: 22px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 400;
    line-height: 100%;
    letter-spacing: -0.02em;
  }
  svg {
    width: 115px;
    height: 115px;
  }

  @media only screen and (min-width: 769px) {
    margin-bottom: 67px;

    .text {
      font-size: 26px;
    }
    svg {
      width: 148px;
      height: 148px;
    }
  }
`

const QuizIndicator = ({
  percent,
  correctNumberAnswer,
  totalNumberQuestion,
  indicatorColor,
}) => {
  return (
    <IndicatorContainer>
      <CircularProgressbar
        value={percent}
        strokeWidth={6}
        styles={buildStyles({
          strokeLinecap: "butt",
          pathColor: `${indicatorColor ? "#FFC700" : "#FF5454"}`,
          trailColor: "#2B2D33",
        })}
      />
      <span className="text">
        {`${correctNumberAnswer} / ${totalNumberQuestion}`}
      </span>
    </IndicatorContainer>
  )
}

export default QuizIndicator
