import { useState } from "react"
import { API } from "@/aws/AmplifyAuthConfigure"
import { GET_CERTIFICATE_PDF } from "@/graphql/fragments"
import { saveAs } from "file-saver"
import styled from "styled-components"
import SecondaryLoader from "@/components/SecondaryLoader"
const BtnGroupContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
  text-align: center;

  .loader {
    min-height: 56px;
    display: flex;
    align-items: center;
    margin: 13px;
  }

  button {
    width: 250px;
    height: 56px;
    margin: 13px;
    background: none;
    border: none;
    font-size: 16px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.02em;
    cursor: pointer;
  }
  .primary {
    background: #ffc700;
    color: #18191b;
  }
  .secondary {
    border: 1px solid #fff;
    border-radius: 1px;
    color: #fff;
  }

  @media (max-width: 768px) {
    margin-bottom: 100px;
    button {
      width: 100%;
      height: 46px;
      margin: 7px 0;
      font-size: 14px;
    }
    .loader {
      min-height: 46px;
      margin: 7px 0;
    }
  }
`
const ButtonGroup = ({ btn, onTryAgain, onToggleScreen }) => {
  const [loading, setLoading] = useState(false)
  const downloadCertificateHandle = async () => {
    setLoading(true)
    try {
      const {
        data: { getPdfCertificate },
      } = await API.graphql({
        query: GET_CERTIFICATE_PDF,
      })
      const res = await JSON.parse(getPdfCertificate)
      if(res.statusCode !== 200) throw res.body

      const byteCharacters = atob(res.body)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      const blob = new Blob([byteArray], { type: "application/pdf" })
      saveAs(blob, "certificate.pdf")
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }
  const tryAgainHandle = () => {
    onTryAgain()
  }
  const goToCourseHandle = () => {
    onToggleScreen()
  }
  const funcsName = {
    goToCourseHandle,
    downloadCertificateHandle,
    tryAgainHandle,
  }

  return (
    <BtnGroupContainer>
      {loading ? (
        <div className="loader">
          <SecondaryLoader />
        </div>
      ) : (
        btn.map(({ classes, text, func }) => {
          return (
            <button
              key={text}
              onClick={funcsName[func]}
              className={classes}
              disabled={loading}
            >
              {text}
            </button>
          )
        })
      )}
    </BtnGroupContainer>
  )
}

export default ButtonGroup
