import { graphql } from "gatsby"
import { gql } from "graphql-request"

export const query = graphql`
  fragment seo on STRAPI_ComponentMainSeo {
    metaTitle
    metaDescription
    pathName
  }

  fragment hero on STRAPI_ComponentMainHero {
    title
    description
  }
  fragment modHero on STRAPI_ComponentMainModHero {
    title
    description
    videoLink
  }

  fragment dataCourse on STRAPI_DataCourse {
    nestedItem {
      labelItem
      totalDuration
      steppersData {
        labelStepper
        stepDuration
        course_content {
          contentTitle
          contentSubtitle
          contentDescription
          videoLink
          attachments {
            title
            link
          }
        }
      }
    }
  }

  fragment dataQuiz on STRAPI_DataQuiz {
    questions {
      name
      title
      variant
      isCorrect
      lessonChapter
      options {
        label
        value
      }
    }
  }
`
export const GET_DATA = gql`
  {
    dataCourse {
      nestedItem {
        labelItem
        totalDuration
        steppersData {
          labelStepper
          stepDuration
          course_content {
            contentTitle
            contentSubtitle
            contentDescription
            videoLink
            attachments {
              title
              link
            }
          }
        }
      }
    }
  }
`

// LEAD STRAPI
// export const GET_LEAD = gql`
//   query leadCourse($id: ID!) {
//     leadCourse(id: $id) {
//       id
//     }
//   }
// `

// QUERY(AWS)
export const GET_DATA_COURSE = `
  {
    getCourse 
  }
`
export const GET_CERTIFICATE_PDF = `
 {
   getPdfCertificate
 }
`